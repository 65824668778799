import React, { Component } from "react";
import { firebase, auth } from "./firebase";

export const UserContext = React.createContext({ user: null });

export class UserState extends Component {
  state = { user: null, error: null };
  componentDidMount() {
    auth.onAuthStateChanged(user => {
      this.setState({ user });
    });
  }

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export class SignInAsGuest extends Component {
  componentDidMount() {
    auth.signInAnonymously();
  }
  render() {
    return <p>Signing in as guest...</p>;
  }
}

export class SignIn extends Component {
  render() {
    return (
      <div class="buttons">
        <button
          class="button"
          onClick={() => {
            const googleProvider = new firebase.auth.GoogleAuthProvider();
            auth.signInWithPopup(googleProvider).catch(error => {
              console.error(error);
            });
          }}
        >
          Sign in with Google
        </button>
      </div>
    );
  }
}
