import React, { Component } from "react";
import "bulma/css/bulma.css";
import "./App.css";
import produce from "immer";
import { db } from "./firebase";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import moment from "moment";

export default class CreatePage extends Component {
  state = { error: null };
  render() {
    return (
      <div>
        {this.state.error && this.errorMessage()}
        <CreateElection
          onSubmit={promise => {
            promise
              .then(ref => {
                this.props.history.push(`/${ref.id}`);
              })
              .catch(error => {
                this.setState({ error });
              });
          }}
        />
      </div>
    );
  }
  errorMessage() {
    return (
      <article class="message is-danger">
        <div class="message-header">
          <p>Error</p>
          <button
            class="delete"
            aria-label="delete"
            onClick={() => {
              this.setState({ error: null });
            }}
          />
        </div>
        <div class="message-body">
          An error occurred while creating your election. Please try again.
        </div>
      </article>
    );
  }
}

class CreateElection extends Component {
  state = {
    prompt: "",
    candidates: [""],
    requireSignIn: false,
    useCutoff: false,
    cutoff: moment().endOf("day"),
    submitting: false,
    validation: []
  };

  componentDidMount() {
    this.intervalId = setInterval(() => {
      if (this.state.candidates[this.state.candidates.length - 1] !== "") {
        this.setState(
          produce(draft => {
            draft.candidates.push("");
          })
        );
      }
    }, 200);
  }

  componentWillDismount() {
    clearInterval(this.intervalId);
  }

  candidates() {
    return this.state.candidates.filter(c => /\S/.test(c));
  }

  validateElection = function*() {
    if (this.state.prompt.trim().length === 0) {
      yield "Prompt is required";
    }

    if (this.state.prompt.length > 200) {
      yield "Prompt must be under 200 characters";
    }

    const n = this.candidates().length;
    if (n < 2) {
      yield "An election must have at least 2 candidates";
    }

    if (n > 50) {
      yield "An election can have no more than 50 candidates";
    }
  };

  validation() {
    if (this.state.validation.length === 0) {
      return null;
    }

    return (
      <article class="message is-warning">
        <div class="message-header">
          <p>Please fix these issues before submitting</p>
          <button
            class="delete"
            aria-label="delete"
            onClick={() => {
              this.setState({ validation: [] });
            }}
          />
        </div>
        <div class="message-body">
          <div class="content">
            <ul>
              {this.state.validation.map((message, i) => (
                <li key={i}>{message}</li>
              ))}
            </ul>
          </div>
        </div>
      </article>
    );
  }

  render() {
    return (
      <div>
        {this.validation()}
        <div class="card">
          <div class="card-content">
            <form
              onSubmit={e => {
                e.preventDefault();
                const validation = Array.from(this.validateElection());
                if (validation.length > 0) {
                  this.setState({ validation });
                  return;
                }

                this.setState({ submitting: true });
                const candidates = this.candidates();
                const indexes = candidates.map((c, i) => i);
                const cutoff = this.state.useCutoff
                  ? this.state.cutoff.valueOf()
                  : null;
                const election = {
                  candidates,
                  prompt: this.state.prompt,
                  requireSignIn: this.state.requireSignIn,
                  cutoff,
                  results: {
                    ballots: 0,
                    outcome: [{ place: 1, indexes }]
                  }
                };
                const promise = db
                  .collection("elections")
                  .add(election)
                  .finally(() => {
                    this.setState({ submitting: false });
                  });
                this.props.onSubmit(promise);
              }}
            >
              <div class="field">
                <label class="label">Prompt</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    placeholder="e.g. Where should we eat tonight?"
                    value={this.state.prompt}
                    onChange={e => this.setState({ prompt: e.target.value })}
                  />
                </div>
              </div>
              <div class="field">
                <label class="label">Candidates</label>
                {this.state.candidates.map((c, i) => (
                  <p
                    key={`edit-candidate-${i}`}
                    class="control has-icons-right candidate-control"
                  >
                    <input
                      class="input"
                      type="text"
                      placeholder={`Candidate #${i + 1}`}
                      value={this.state.candidates[i]}
                      onChange={e => {
                        const value = e.target.value;
                        this.setState(
                          produce(draft => {
                            draft.candidates[i] = value;
                          })
                        );
                      }}
                    />
                    {i !== this.state.candidates.length - 1 && (
                      <div
                        class="icon is-small is-right clickable-icon"
                        onClick={() => {
                          this.setState(
                            produce(draft => {
                              draft.candidates.splice(i, 1);
                            })
                          );
                        }}
                      >
                        <i class="fas fa-times" />
                      </div>
                    )}
                  </p>
                ))}
              </div>
              <hr />
              <div class="field">
                <div class="control">
                  <label class="checkbox">
                    <input
                      type="checkbox"
                      checked={this.state.requireSignIn}
                      onChange={e => {
                        this.setState({ requireSignIn: e.target.checked });
                      }}
                    />{" "}
                    Require sign in to vote
                  </label>
                </div>
              </div>
              <EndDatePicker
                useCutoff={this.state.useCutoff}
                setUseCutoff={useCutoff => {
                  this.setState({ useCutoff });
                }}
                cutoff={this.state.cutoff}
                onSelect={cutoff => {
                  this.setState({ cutoff });
                }}
              />
              <hr />
              <div class="field">
                <div class="control">{this.createButton()}</div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
  createButton() {
    if (this.state.submitting) {
      return <button class="button is-primary is-loading">Loading</button>;
    } else {
      return (
        <button class="button is-primary" type="submit">
          Create
        </button>
      );
    }
  }
}

class EndDatePicker extends Component {
  render() {
    return (
      <React.Fragment>
        <div class="field">
          <div class="control">
            <label class="checkbox">
              <input
                type="checkbox"
                checked={this.props.useCutoff}
                onChange={e => {
                  this.props.setUseCutoff(e.target.checked);
                }}
              />{" "}
              Close the election after a certain day
            </label>
          </div>
        </div>
        {this.picker()}
      </React.Fragment>
    );
  }
  picker() {
    if (!this.props.useCutoff) {
      return null;
    }

    return (
      <div class="field">
        <label class="label">End Date</label>
        <div class="control">
          <DatePicker
            className="input"
            minDate={moment()}
            selected={this.props.cutoff}
            onSelect={date => {
              const cutoff = date.endOf("day");
              this.props.onSelect(cutoff);
            }}
          />
        </div>
      </div>
    );
  }
}
