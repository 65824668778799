import React, { Component } from "react";
import "bulma/css/bulma.css";
import "./App.css";

export default class NotFound extends Component {
  render() {
    return (
      <div class="content">
        <h2>Not found</h2>
      </div>
    );
  }
}
