import React, { Component } from "react";
import "bulma/css/bulma.css";
import "./App.css";
import { db } from "./firebase";
import moment from "moment";
import pluralize from "pluralize";

export default class Results extends Component {
  state = { error: null, loading: true, election: null };
  componentDidMount() {
    const eid = this.props.match.params.eid;
    const ref = db.collection("elections").doc(eid);
    this.unsubscribe = ref.onSnapshot(
      doc => {
        const election = doc.exists ? doc.data() : null;
        this.setState({ election, loading: false });
      },
      error => {
        this.setState({ error, loading: false });
      }
    );
  }
  componentWillUnmount() {
    this.unsubscribe();
  }
  render() {
    if (this.state.error) {
      return <p>An error occurred loading the election results.</p>;
    }

    if (this.state.loading) {
      return <p>Loading...</p>;
    }

    const election = this.state.election;
    const eid = this.props.match.params.eid;

    if (!election) {
      return <p>Election '{eid}' not found</p>;
    }

    if (election.cutoff && moment(election.cutoff).isBefore()) {
      return <ClosedResults election={election} />;
    }

    if (!election.results) {
      return <p>Votes are being counted...</p>;
    }

    return (
      <div class="content">
        <h2>{election.prompt}</h2>
        <Outcome election={election} />
        <p>
          {pluralize("ballot", election.results.ballots, true)}{" "}
          {pluralize("is", election.results.ballots)} in. Results will be
          updated as new ballots arrive.
        </p>
        {this.cutoffMessage()}
        <p>
          <a href={`/${eid}`}>Change your vote.</a>
        </p>
      </div>
    );
  }
  cutoffMessage() {
    const cutoff = this.state.election.cutoff;
    if (!cutoff) {
      return null;
    }
    return <p>This election will close {moment(cutoff).fromNow()}.</p>;
  }
}

class ClosedResults extends Component {
  render() {
    const { election } = this.props;
    return (
      <div class="content">
        <h2>{election.prompt}</h2>
        <Outcome election={election} />
        <p>
          This election closed {moment(election.cutoff).fromNow()}.{" "}
          {pluralize("ballot", election.results.ballots, true)}{" "}
          {pluralize("was", election.results.ballots)} counted.
        </p>
      </div>
    );
  }
}

class Outcome extends Component {
  render() {
    const { election } = this.props;
    return (
      <ol>
        {election.results.outcome.map(rank => (
          <li key={rank.place} value={rank.place}>
            {rank.indexes.map(i => election.candidates[i]).join(", ")}
          </li>
        ))}
      </ol>
    );
  }
}
