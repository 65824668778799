import React, { Component } from "react";
import "bulma/css/bulma.css";
import "./App.css";
import { auth } from "./firebase";
import { UserContext } from "./UserState";
import classNames from "classnames";

export default class Header extends Component {
  render() {
    return (
      <header class="header">
        <div class="header-section header-left">
          <a href="/" class="header-title">
            Elect Correct
          </a>
        </div>
        <div class="header-section header-right">
          <UserContext.Consumer>
            {user => this.signOut(user)}
          </UserContext.Consumer>
        </div>
      </header>
    );
  }
  signOut(user) {
    if (user && user.emailVerified) {
      return <UserDropdown user={user} />;
    }
    return null;
  }
}

class UserDropdown extends Component {
  state = { active: false };
  render() {
    const { user } = this.props;
    return (
      <div
        class={classNames("dropdown", "is-right", {
          "is-active": this.state.active
        })}
      >
        <div class="dropdown-trigger">
          <a
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={() => {
              this.setState({ active: !this.state.active });
            }}
          >
            <img class="header-user-photo" src={user.photoURL} alt="Profile" />
          </a>
        </div>
        <div class="dropdown-menu" id="dropdown-menu" role="menu">
          <div class="dropdown-content">
            <div class="dropdown-item">
              <b>{user.displayName}</b>
              <p>{user.email}</p>
            </div>
            <hr class="dropdown-divider" />
            <a
              class="dropdown-item"
              onClick={() => {
                auth.signOut();
              }}
            >
              Sign out
            </a>
          </div>
        </div>
      </div>
    );
  }
}
