import React, { Component } from "react";
import "bulma/css/bulma.css";
import "./App.css";
import BallotCandidate from "./BallotCandidate";
import BallotCandidateGrouping from "./BallotCandidateGrouping";

export default class AboutPage extends Component {
  render() {
    return (
      <div class="content">
        <h2 id="how">How does this work?</h2>
        <p>A ballot shows a list of candidates like so:</p>
        <Example>
          <BallotCandidateGrouping>
            {this.candidate("Red", true, true)}
            {this.candidate("Yellow", true, true)}
            {this.candidate("Green", true, true)}
            {this.candidate("Blue", true, true)}
          </BallotCandidateGrouping>
        </Example>
        <p>
          The grey box around the candidates means that you prefer them equally.
          You can rank a candidate higher or lower by clicking the arrows to the
          right.
        </p>
        <p>
          Using the arrows, you can arrange the candidates in to ranked groups,
          like so:
        </p>
        <Example>
          <BallotCandidateGrouping>
            {this.candidate("Green", false, true)}
          </BallotCandidateGrouping>
          <BallotCandidateGrouping>
            {this.candidate("Blue", true, true)}
            {this.candidate("Red", true, true)}
          </BallotCandidateGrouping>
          <BallotCandidateGrouping>
            {this.candidate("Yellow", true, false)}
          </BallotCandidateGrouping>
        </Example>
        <p>This ballot expresses the following:</p>
        <ul>
          <li>I prefer green to all other candidates.</li>
          <li>I don't have a preference between blue and red.</li>
          <li>I prefer every candidate to yellow.</li>
        </ul>
        <h2 id="why">Why is this better than just voting for your favorite?</h2>
        <p>
          A more expressive ballot lets you rank your choices, and also express
          indifference. Because of this, voting for your favorite candidate
          won't help elect your least favorite candidate.
        </p>
        <p>
          Ballots are evaluated using the{" "}
          <a
            href="https://en.wikipedia.org/wiki/Schulze_method"
            target="_blank"
            rel="noopener noreferrer"
          >
            Schulze Method
          </a>, which does a pretty good job evaluating the aggregate of
          everybody's preferences.
        </p>
      </div>
    );
  }
  candidate(text, up, down) {
    return (
      <BallotCandidate
        showUpButton={up}
        showDownButton={down}
        onMoveUp={() => {}}
        onMoveDown={() => {}}
      >
        {text}
      </BallotCandidate>
    );
  }
}

class Example extends Component {
  render() {
    return (
      <div>
        <hr />
        {this.props.children}
        <hr />
      </div>
    );
  }
}
