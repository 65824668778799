import React, { Component } from "react";
import "bulma/css/bulma.css";
import "./App.css";

export default class BallotCandidate extends Component {
  render() {
    return (
      <div class="box ballot-candidate">
        {this.props.children}
        <div class="buttons has-addons is-pulled-right">
          {this.props.showUpButton && (
            <a onClick={this.props.onMoveUp}>
              <span class="icon">
                <i class="fas fa-angle-up" aria-hidden="true" />
              </span>
            </a>
          )}
          {this.props.showDownButton && (
            <a onClick={this.props.onMoveDown}>
              <span class="icon">
                <i class="fas fa-angle-down" aria-hidden="true" />
              </span>
            </a>
          )}
        </div>
      </div>
    );
  }
}
