import React, { Component } from "react";
import "bulma/css/bulma.css";
import "./App.css";
import { db } from "./firebase";
import { UserContext, SignInAsGuest, SignIn } from "./UserState";
import produce from "immer";
import { CopyToClipboard } from "react-copy-to-clipboard";
import BallotCandidate from "./BallotCandidate";
import BallotCandidateGrouping from "./BallotCandidateGrouping";
import moment from "moment";

export default class BallotPage extends Component {
  render() {
    const eid = this.props.match.params.eid;
    return (
      <ElectionLoader eid={eid}>
        {election => (
          <UserContext.Consumer>
            {user => this.ballot(eid, election, user)}
          </UserContext.Consumer>
        )}
      </ElectionLoader>
    );
  }
  ballot(eid, election, user) {
    return (
      <ElectionAuth election={election} user={user}>
        {() => (
          <BallotLoader eid={eid} uid={user.uid}>
            {ballot => (
              <Ballot
                eid={eid}
                uid={user.uid}
                election={election}
                ballot={ballot}
                onSubmitted={() => {
                  this.props.history.push(`/${eid}/results`);
                }}
              />
            )}
          </BallotLoader>
        )}
      </ElectionAuth>
    );
  }
}

class ElectionLoader extends Component {
  state = { election: null, error: null, loading: true };
  componentDidMount() {
    db
      .collection("elections")
      .doc(this.props.eid)
      .get()
      .then(doc => {
        this.setState({ election: doc.data() });
      })
      .catch(error => {
        this.setState({ error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  render() {
    if (this.state.error) {
      return <p>Error loading election '{this.props.eid}'</p>;
    }

    if (this.state.loading) {
      return <p>Loading election...</p>;
    }

    if (!this.state.election) {
      return <p>Couldn't find election '{this.props.eid}'</p>;
    }

    return this.props.children(this.state.election);
  }
}

class ElectionAuth extends Component {
  render() {
    const user = this.props.user;
    if (this.props.election.requireSignIn) {
      if (user && user.emailVerified) {
        return this.props.children();
      } else {
        return (
          <div class="content">
            <p>This election requires that you sign in before voting.</p>
            <SignIn />
          </div>
        );
      }
    }

    if (user) {
      return this.props.children();
    } else {
      return <SignInAsGuest />;
    }
  }
}

class BallotLoader extends Component {
  state = { error: null, loading: true, ballot: null };
  componentDidMount() {
    db
      .collection("elections")
      .doc(this.props.eid)
      .collection("ballots")
      .doc(this.props.uid)
      .get()
      .then(doc => {
        this.setState({ ballot: doc.data() });
      })
      .catch(error => {
        this.setState({ error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }
  render() {
    if (this.state.error) {
      return <p>Error loading ballot</p>;
    }

    if (this.state.loading) {
      return <p>Loading ballot...</p>;
    }

    return this.props.children(this.state.ballot);
  }
}

class Ballot extends Component {
  render() {
    const cutoff = this.props.election.cutoff;
    if (cutoff && moment(cutoff).isBefore()) {
      return <ClosedBallot {...this.props} />;
    }

    return <OpenBallot {...this.props} />;
  }
}

class OpenBallot extends Component {
  state = {
    submitting: false,
    error: null,
    copied: false,
    ballot: this.props.ballot || {
      ranks: [{ indexes: this.props.election.candidates.map((c, i) => i) }]
    }
  };
  submitBallot() {
    this.setState({ submitting: true });
    db
      .collection("elections")
      .doc(this.props.eid)
      .collection("ballots")
      .doc(this.props.uid)
      .set(this.state.ballot)
      .then(() => {
        this.props.onSubmitted();
      })
      .catch(error => {
        this.setState({ error });
      })
      .finally(() => {
        this.setState({ submitting: false });
      });
  }
  render() {
    if (this.state.error) {
      return <p>An error occurred submitting your ballot.</p>;
    }

    const election = this.props.election;
    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          this.submitBallot();
        }}
      >
        <div class="content">
          <h2>{election.prompt}</h2>
        </div>
        <Ranks
          candidates={election.candidates}
          ranks={this.state.ballot.ranks}
          editRanks={f => {
            this.setState(
              produce(draft => {
                f(draft.ballot.ranks);
              })
            );
          }}
        />
        {this.cutoffInfo()}
        {this.aboutLink()}
        {this.ballotButtons()}
      </form>
    );
  }
  cutoffInfo() {
    const cutoff = this.props.election.cutoff;
    if (!cutoff) {
      return null;
    }

    const cutoffMoment = moment(cutoff);
    return (
      <p>
        <i>
          This election will close{" "}
          <span title={cutoffMoment.format("MMMM Do YYYY, h:mm:ss a")}>
            {cutoffMoment.fromNow()}
          </span>.
        </i>
      </p>
    );
  }
  aboutLink() {
    return (
      <div class="content">
        <p>
          <a href="/about" target="_blank" rel="noopener noreferrer">
            How does this work?
          </a>
        </p>
      </div>
    );
  }
  ballotButtons() {
    return (
      <React.Fragment>
        <div class="buttons">
          {this.submitButton()}
          {this.copyButton()}
        </div>
        {this.copyMessage()}
      </React.Fragment>
    );
  }
  submitButton() {
    if (this.props.submitting) {
      return (
        <button class="button is-primary is-loading" type="submit">
          Submitting...
        </button>
      );
    } else {
      return (
        <button class="button is-primary" type="submit">
          Cast your vote
        </button>
      );
    }
  }
  copyButton() {
    return (
      <CopyToClipboard
        text={window.location}
        onCopy={() => {
          this.setState({ copied: true });
        }}
      >
        <a class="button">Share Ballot</a>
      </CopyToClipboard>
    );
  }
  copyMessage() {
    if (!this.state.copied) {
      return null;
    }

    return (
      <div class="content">
        <p class="has-text-weight-light">
          The link to this ballot has been copied to your clipboard.
        </p>
      </div>
    );
  }
}

class Ranks extends Component {
  render() {
    const { ranks, candidates, editRanks } = this.props;
    return (
      <React.Fragment>
        {ranks.map((rank, ri) => (
          <BallotCandidateGrouping key={ri}>
            {rank.indexes.map((ci, cii) => (
              <BallotCandidate
                key={ci}
                showUpButton={!(ri === 0 && rank.indexes.length === 1)}
                showDownButton={
                  !(ri === ranks.length - 1 && rank.indexes.length === 1)
                }
                onMoveUp={() => {
                  editRanks(state => {
                    if (rank.indexes.length === 1) {
                      state[ri - 1].indexes.push(ci);
                      state.splice(ri, 1);
                    } else {
                      state[ri].indexes.splice(cii, 1);
                      state.splice(ri, 0, { indexes: [ci] });
                    }
                  });
                }}
                onMoveDown={() => {
                  editRanks(state => {
                    if (rank.indexes.length === 1) {
                      state[ri + 1].indexes.unshift(ci);
                      state.splice(ri, 1);
                    } else {
                      state[ri].indexes.splice(cii, 1);
                      state.splice(ri + 1, 0, { indexes: [ci] });
                    }
                  });
                }}
              >
                {candidates[ci]}
              </BallotCandidate>
            ))}
          </BallotCandidateGrouping>
        ))}
      </React.Fragment>
    );
  }
}

class ClosedBallot extends Component {
  render() {
    const { election, eid } = this.props;
    const cutoffMoment = moment(election.cutoff);
    return (
      <div>
        {this.vote()}
        <div class="content">
          <p>
            This election was closed{" "}
            <span title={cutoffMoment.format("MMMM Do YYYY, h:mm:ss a")}>
              {cutoffMoment.fromNow()}
            </span>. <a href={`/${eid}/results`}>View the results.</a>
          </p>
        </div>
      </div>
    );
  }
  vote() {
    const { ballot, election } = this.props;
    if (!ballot) {
      return null;
    }

    return (
      <React.Fragment>
        <div class="content">
          <h2>{election.prompt}</h2>
        </div>
        {ballot.ranks.map((rank, ri) => (
          <BallotCandidateGrouping key={ri}>
            {rank.indexes.map((ci, cii) => (
              <BallotCandidate
                key={ci}
                showUpButton={false}
                showDownButton={false}
              >
                {election.candidates[ci]}
              </BallotCandidate>
            ))}
          </BallotCandidateGrouping>
        ))}
      </React.Fragment>
    );
  }
}
