import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyAVi9KXBqEQoQINN6MTxCKkeItBqvZaJfY",
  authDomain: "electcorrect.com",
  databaseURL: "https://schulze-vote.firebaseio.com",
  projectId: "schulze-vote",
  storageBucket: "schulze-vote.appspot.com",
  messagingSenderId: "489421282885"
});

export { firebase };
export const auth = firebase.auth();
export const db = firebase.firestore();

db.settings({ timestampsInSnapshots: true });
