import React, { Component } from "react";
import "bulma/css/bulma.css";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { UserState, UserContext } from "./UserState";
import Header from "./Header";
import BallotPage from "./BallotPage";
import ResultsPage from "./ResultsPage";
import CreatePage from "./CreatePage";
import AboutPage from "./AboutPage";
import NotFound from "./NotFound";

export default class App extends Component {
  render() {
    return (
      <Router>
        <UserState>
          <Header />
          <section class="section">
            <div class="container main-container">
              <Switch>
                <Route exact path="/" component={CreatePage} />
                <Route exact path="/about" component={AboutPage} />
                <Route
                  exact
                  path="/:eid"
                  render={props => (
                    <UserContext.Consumer>
                      {user => <BallotPage {...props} user={user} />}
                    </UserContext.Consumer>
                  )}
                />
                <Route path="/:eid/results" component={ResultsPage} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </section>
        </UserState>
      </Router>
    );
  }
}
